import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { Colors } from '../../../../../../componentLibrary/Colors';
import { useTranslation } from 'react-i18next';
import { InitGraphLegendEntry } from './components/InitGraphLegendEntry';
import { DisplayDate } from '../../../../../../../components/DisplayDate';
import { InitiativeGraphSkeleton } from './InitiativeGraph skeleton';
import dayjs from 'dayjs';

const Y_AXIS_OFFSET = 24;

interface Props {
  startDate?: string | null;
  endDate?: string | null;
  periodData?: {
    date: string;
    completed?: number | null;
    total?: number | null;
  }[];

  legendGoal?: number | null;
  legendCompleted?: number | null;
  showLegends?: boolean;
  width?: number;
  height?: number;
}

export const InitiativeGraph = ({
  periodData,
  startDate,
  endDate,
  legendGoal,
  legendCompleted,
  height = 50,
}: Props) => {
  const { t } = useTranslation();
  if (periodData == null || periodData.length === 0) {
    // draw a placeholder empty graph
    periodData = [
      { date: '', total: 0 },
      { date: '', total: 0 },
    ];
  }

  startDate = startDate ?? periodData[0].date;
  endDate = endDate ?? periodData[periodData.length - 1].date ?? '--';

  const closestPeriod = periodData.reduce((prev, curr) => {
    return Math.abs(diffFromNow(curr.date)) < Math.abs(diffFromNow(prev.date))
      ? curr
      : prev;
  });

  const lastRisingPeriod = findLastRisingPeriod(periodData);

  const lastPeriodToHaveCompleted =
    lastRisingPeriod && dayjs(lastRisingPeriod.date).isAfter(closestPeriod.date)
      ? lastRisingPeriod
      : closestPeriod;

  const remappedPeriodData = periodData.map((period) => {
    if (dayjs(period.date).isSameOrBefore(lastPeriodToHaveCompleted.date))
      return period;

    return {
      ...period,
      completed: null,
    };
  });

  return (
    <div style={{ marginLeft: -Y_AXIS_OFFSET }}>
      <ResponsiveContainer debounce={300} height={height} width="100%">
        <LineChart height={height} data={remappedPeriodData}>
          <XAxis dataKey={'date'} tick={false} />
          <YAxis
            tickLine={false}
            width={20}
            fontSize={10}
            tickSize={2}
            tickCount={5}
            interval={0}
            type="number"
          />
          <CartesianGrid vertical={false} strokeDasharray="2 4" />
          <Line
            dataKey="total"
            stroke={Colors.Howwe.MAIN_ORANGE}
            strokeWidth={2}
            isAnimationActive={false}
            dot={false}
          />
          <Line
            dataKey="completed"
            stroke={Colors.Action.BLUE}
            strokeWidth={2}
            isAnimationActive={false}
            dot={false}
          />
          <ReferenceLine
            x={closestPeriod.date}
            stroke={Colors.Action.BLUE}
            strokeDasharray="3 3"
          />
        </LineChart>
      </ResponsiveContainer>
      <div
        className="flx flx--jc-space-between txt--secondary"
        style={{ marginLeft: Y_AXIS_OFFSET }}
      >
        <DisplayDate date={startDate} />
        <div className="flx flx--gap">
          <InitGraphLegendEntry
            label={t('common.completed')}
            value={legendCompleted}
            type="completed"
          />
          <InitGraphLegendEntry
            label={t('common.total')}
            value={legendGoal}
            type="goal"
          />
        </div>
        <DisplayDate date={endDate} />
      </div>
    </div>
  );
};
const now = dayjs();

const diffFromNow = (date1: string) => {
  return dayjs(date1).diff(now);
};

function findLastRisingPeriod(periodData: Period[]): Period | null {
  if (periodData.length === 0) return null;

  let lastRisingPeriod: Period | null = null;

  for (let i = 1; i < periodData.length; i++) {
    const prevCompleted = periodData[i - 1].completed ?? 0;
    const currentCompleted = periodData[i].completed ?? 0;

    // If the current `completed` value is greater than the previous, update the last rising period
    if (currentCompleted > prevCompleted) {
      lastRisingPeriod = periodData[i];
    }
  }

  return lastRisingPeriod;
}

InitiativeGraph.Skeleton = InitiativeGraphSkeleton;

type Period = {
  date: string;
  completed?: number | null;
  total?: number | null;
};

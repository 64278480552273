import { gql, useSuspenseQuery } from '@apollo/client';
import {
  DurationStatus,
  GetTopInitiativesForStartPageDocument,
  InitiativeStartPage__TopInitiativeReportFragment,
} from '../../../../generated/graphql';
import { Col, Divider, Row } from 'antd';
import { InitiativeCard } from '../../../../components/initiatives/initiativeCard/InitiativeCard';
import { ToggleSection } from '../../../team/overview/components/ToggleSection';
import { InitiativeTable } from '../../strategicPlan/components/InitiativeSection/InitiativeTable';
import { useTranslation } from 'react-i18next';
import { InitiativeStartPageSkeleton } from './InitiativeStartPage.Skeleton';
import {
  MilestoneOrActiveToggle,
  MilestoneOrActiveToggleEnum,
} from '../initiativeDetails/milestoneOrActivityToggle/MilestoneOrActiveToggle';
import { useState } from 'react';

export const InitiativeStartPage = () => {
  const { data } = useSuspenseQuery(GetTopInitiativesForStartPageDocument, {
    fetchPolicy: 'cache-first',
  });
  const topInitiativeReports = data?.topInitiativeReport.initiatives ?? [];

  const companyInitiatives = topInitiativeReports.filter(
    (tr) => tr.companyInitiative
  );
  const otherInitiatives = topInitiativeReports.filter(
    (tr) => !tr.companyInitiative
  );

  return (
    <div>
      <InitiativeStartPageGroup
        initiatives={companyInitiatives}
        companyInitiativeSection
      />
      <Divider />
      <InitiativeStartPageGroup initiatives={otherInitiatives} />
    </div>
  );
};

interface InitiativeStartPageGroupProps {
  initiatives: InitiativeStartPage__TopInitiativeReportFragment[];
  companyInitiativeSection?: boolean;
}

const InitiativeStartPageGroup = ({
  companyInitiativeSection = false,
  initiatives,
}: InitiativeStartPageGroupProps) => {
  const { t } = useTranslation();
  const [milestonesOrActivities, setMilestoneOrActivities] =
    useState<MilestoneOrActiveToggleEnum>(
      MilestoneOrActiveToggleEnum.Milestones
    );
  const translationKey = companyInitiativeSection
    ? 'companyInitiatives'
    : 'otherInitiatives';
  return (
    <div className="mb--xl">
      <h2 className="mb--xl">
        {t(`InitiativeStartPage.${translationKey}.title`)}
      </h2>
      <h3 className="mb--l">
        {t(`InitiativeStartPage.${translationKey}.activeTitle`)}
      </h3>
      <div className="mb--l">
        <MilestoneOrActiveToggle
          value={milestonesOrActivities}
          onChange={setMilestoneOrActivities}
        />
      </div>

      <Row gutter={[18, 18]} className="mb--xl">
        {initiatives
          .filter((i) => i.initiative.metadata.status === DurationStatus.ACTIVE)
          .map((topInitiativeReport) => (
            <Col xs={12} xl={8} key={topInitiativeReport.initiative.id}>
              <InitiativeCard
                milestonesOrActivities={milestonesOrActivities}
                domainId={topInitiativeReport.domainId}
                name={topInitiativeReport.initiative.name}
                tag={topInitiativeReport.initiative.tag}
                accumulatedStats={topInitiativeReport.accumulatedStats}
              />
            </Col>
          ))}
      </Row>
      <div className="flx flx--jc-center flx--column">
        <ToggleSection
          titleExpanded={t(`InitiativeStartPage.${translationKey}.showLess`)}
          titleNotExpanded={t(`InitiativeStartPage.${translationKey}.showMore`)}
          data-intercom-target="Expandable section to show completed and upcoming Company Initiatives"
          disabled={false}
        >
          <div className="flx flx--jc-space-between mb">
            <h3>
              {t(`InitiativeStartPage.${translationKey}.completedAndUpcoming`)}
            </h3>
          </div>
          <InitiativeTable
            milestonesOrActivities={milestonesOrActivities}
            initiatives={initiatives.filter(
              (i) =>
                i.initiative.metadata.status === DurationStatus.COMPLETED ||
                i.initiative.metadata.status === DurationStatus.UPCOMING
            )}
          />
        </ToggleSection>
      </div>
    </div>
  );
};

InitiativeStartPage.Skeleton = InitiativeStartPageSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_INITIATIVES_START_PAGE = gql`
  query getTopInitiativesForStartPage(
    $tenantId: ID
    $filter: TopInitiativeFilter
  ) {
    topInitiativeReport(tenantId: $tenantId, filter: $filter) {
      initiatives {
        ...InitiativeStartPage__TopInitiativeReport
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_START_PAGE__TOP_INITIATIVE_REPORT = gql`
  fragment InitiativeStartPage__TopInitiativeReport on TopInitiativeReport {
    id
    domainId {
      itemId
      tenantId
    }
    companyInitiative
    initiative {
      metadata {
        status
      }
    }
    ...InitiativeCard__TopInitiativeReport
    ...initiativeTable__TopInitiativeReport
  }
`;

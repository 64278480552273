import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useAuth0 } from './Auth0Provider';
import { ConfigProvider as AntDesignConfig, Spin } from 'antd';
import moment from 'moment';
import en_GB from 'antd/locale/en_GB';
import sv_SE from 'antd/locale/sv_SE';
import 'moment/dist/locale/sv';
import { envConf } from './envConf';
import i18next from 'i18next';
import { useGa4 } from './hooks/useGA4';
import { NewVersionBanner } from './components/newVersionBanner/NewVersionBanner';
import { useBasicTagsForSentry } from './useSentryBasicTags';
import { createSUID } from './createSUID';
import { useIntercom } from './hooks/useIntercom';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useCurrentLocaleLazy } from './hooks/useCurrentLocale';
import React from 'react';
import { CompanyRoutes } from './CompanyRoutes';
import dayjs from 'dayjs';
import locale_sv from 'dayjs/locale/sv';
import locale_en from 'dayjs/locale/en-gb'; // ES 2015
import { Colors } from './appPages/componentLibrary/Colors';
import { useMyPermissionRoles } from './usePermissions';
import { useTranslation } from 'react-i18next';

const locales = {
  sv: locale_sv,
  en: locale_en,
};

const AdminPages = React.lazy(() =>
  import('./adminPages/AdminRoutes').then(({ AdminRoutes }) => ({
    default: AdminRoutes,
  }))
);
const ComponentLibrary = React.lazy(() =>
  import('./appPages/ComponentLibrary').then(({ ComponentLibrary }) => ({
    default: ComponentLibrary,
  }))
);

// Used by sentry for traceability purposes
// A user will get a new sessionId everytime they reload the app or open howwe in a new tab/window
export const sessionId = createSUID();
const App = () => {
  useIntercom();
  useBasicTagsForSentry();

  const { loading: checkingAuth } = useAuth0();
  const { GA4_ID } = envConf;

  useGa4(GA4_ID);

  const [loadLocale, { data }] = useCurrentLocaleLazy();
  const { isSuperAdmin, isTenantAdmin } = useMyPermissionRoles();

  const isAdmin = isSuperAdmin || isTenantAdmin;
  const remoteLocale = data?.me.locale;
  const i18nextLanguage = i18next.language;

  useEffect(() => {
    if (!checkingAuth) {
      loadLocale();
    }
  }, [checkingAuth, loadLocale]);

  useEffect(() => {
    moment.locale(i18nextLanguage, {
      week: {
        dow: 1,
      },
    });

    dayjs.locale(i18nextLanguage, {
      ...locales[i18nextLanguage as 'en' | 'sv'],
      weekStart: 1,
    });
  }, [i18nextLanguage]);

  useEffect(() => {
    if (remoteLocale == null || remoteLocale === i18nextLanguage) return;

    const timerId = setTimeout(() => {
      /* Currently the only way to prevent the below warning, when changing language on the user settings page, without rewriting the
       entire UserBasicSettingsPage and its modal is to delay the `i18next.changeLanguage` call to next tick so that
       setState in any child components (UserPage) has time to settle before triggering a re-render

       👇The warning we get if we do not use setTimeout
       "Warning: Cannot update a component (`UserPage`) while rendering a different component (`App`)"
      */
      i18next.changeLanguage(remoteLocale);
    }, 0);

    return () => clearTimeout(timerId);
  }, [remoteLocale, i18nextLanguage]);

  const defaultFormValidationMessages = useDefaultFormValidationMessages();

  if (checkingAuth) {
    return (
      <div className="center-content">
        <Spin size="large" delay={300} />
      </div>
    );
  }

  return (
    <AntDesignConfig
      form={{
        validateMessages: defaultFormValidationMessages,
      }}
      theme={{
        token: {
          colorPrimary: Colors.Howwe.MAIN_BLUE,
          fontSize: 14,
          fontFamily: 'Mulish, sans-serif',
          borderRadius: 4,
          colorText: Colors.Grays.MAIN_FONT,
        },
        components: {
          Form: {
            labelColor: Colors.Grays.SECONDARY_FONT,
            labelFontSize: 11,
            itemMarginBottom: 12,
            verticalLabelPadding: '0 0 4px 0',
          },
          Layout: {
            headerBg: Colors.Grays.WHITE,
          },
          Modal: { wireframe: true },
          Dropdown: {
            colorText: Colors.Action.BLUE,
            fontWeightStrong: 600,
          },
          Button: {
            fontWeight: 600,
            controlOutline: 'none', //removes boxShadow on buttons
            colorPrimary: Colors.Action.BLUE,
            colorPrimaryHover: Colors.Action.HOVER_BLUE,
          },
          Radio: {
            colorPrimary: Colors.Action.BLUE,
            colorPrimaryHover: Colors.Action.HOVER_BLUE,
          },
          Table: { colorTextHeading: Colors.Grays.SECONDARY_FONT },
          Tabs: {
            colorPrimary: Colors.Action.SELECTED_BLUE,
            itemColor: Colors.Grays.SECONDARY_FONT,
            colorPrimaryHover: Colors.Action.BLUE,
          },
          Select: {
            optionSelectedBg: Colors.Action.LIGHT_ACTIVE,
            controlItemBgHover: Colors.Action.HOVER_WHITE_TO_BLUE,
          },
        },
      }}
      locale={i18nextLanguage === 'sv' ? sv_SE : en_GB}
    >
      <Helmet
        htmlAttributes={{
          lang: i18nextLanguage,
        }}
        titleTemplate="%s - Howwe"
      />
      <NewVersionBanner />

      <Switch>
        <Route path="/admin">{isAdmin && <AdminPages />}</Route>
        <Route path="/component-library">
          <ComponentLibrary />
        </Route>
        <Route path="/">
          <CompanyRoutes />
        </Route>
      </Switch>
    </AntDesignConfig>
  );
};

export default Sentry.withProfiler(App);

const useDefaultFormValidationMessages = () => {
  const { t } = useTranslation();

  const typeTemplate = t('common.validation.typeTemplate');

  return {
    default: t('common.validation.default'),
    required: t('common.validation.required'),
    enum: t('common.validation.enum'),
    whitespace: t('common.validation.whitespace'),
    date: {
      format: t('common.validation.date.format'),
      parse: t('common.validation.date.parse'),
      invalid: t('common.validation.date.invalid'),
    },
    types: {
      string: typeTemplate,
      method: typeTemplate,
      array: typeTemplate,
      object: typeTemplate,
      number: typeTemplate,
      date: typeTemplate,
      boolean: typeTemplate,
      integer: typeTemplate,
      float: typeTemplate,
      regexp: typeTemplate,
      email: typeTemplate,
      url: typeTemplate,
      hex: typeTemplate,
    },
    string: {
      len: t('common.validation.string.len'),
      min: t('common.validation.string.min'),
      max: t('common.validation.string.max'),
      range: t('common.validation.string.range'),
    },
    number: {
      len: t('common.validation.number.len'),
      min: t('common.validation.number.min'),
      max: t('common.validation.number.max'),
      range: t('common.validation.number.range'),
    },
    array: {
      len: t('common.validation.array.len'),
      min: t('common.validation.array.min'),
      max: t('common.validation.array.max'),
      range: t('common.validation.array.range'),
    },
    pattern: {
      mismatch: t('common.validation.pattern.mismatch'),
    },
  };
};

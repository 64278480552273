import { Popover, Typography } from 'antd';
import './InitiativeCard.less';
import { Colors } from '../../../appPages/componentLibrary/Colors';
import { ManageSearchIcon } from '../../../icons/ManageSearch';
import { Link } from 'react-router-dom';
import { gql } from '@apollo/client';
import { InitiativePreviewGraph } from './InitiativePreviewGraph';
import { GantishPopoverContent } from './GantishPopoverContent';
import { Suspense } from 'react';
import { MilestoneOrActiveToggleEnum } from '../../../appPages/company/initiatives/initiativeDetails/milestoneOrActivityToggle/MilestoneOrActiveToggle';
import { InitiativeTag } from '../../initiative/InitiativeTag';
import { ProgressStats } from '../ProgressStats';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
  tag?: {
    title: string;
    iconId: string;
    colorCode: string;
  };
  domainId: {
    itemId: string;
    tenantId: string;
  };
  accumulatedStats: {
    milestones: {
      completed: number;
      overdue: number;
      upcoming: number;
      graph: {
        date: string;
        completed: number;
        total: number;
      }[];
    };
    activities: {
      completed: number;
      overdue: number;
      upcoming: number;
      graph: {
        date: string;
        completed: number;
        total: number;
      }[];
    };
    subInitiatives: {
      total: number;
    };
  };
  milestonesOrActivities: MilestoneOrActiveToggleEnum;
}

export const InitiativeCard = ({
  name,
  tag,
  domainId,
  accumulatedStats,
  milestonesOrActivities,
}: Props) => {
  const { t } = useTranslation();
  const stats =
    milestonesOrActivities === MilestoneOrActiveToggleEnum.Milestones
      ? accumulatedStats.milestones
      : accumulatedStats.activities;

  return (
    <Popover
      content={
        <Suspense fallback={<GantishPopoverContent.Skeleton />}>
          <GantishPopoverContent initiativeDomainId={domainId} />
        </Suspense>
      }
      placement="bottom"
      mouseEnterDelay={0.6}
      overlayStyle={{
        width: '100%',
        paddingLeft: 30,
        paddingRight: 30,
        maxWidth: 1200,
      }}
    >
      <Link
        to={`/company/initiatives/${domainId.itemId}`}
        className="InitiativesCard__card flx--column flx--jc-space-between"
      >
        <div>
          <div className="flx flx--jc-space-between">
            <div className="flx">
              {tag && (
                <InitiativeTag tag={tag} hideTitle className="mr" height={30} />
              )}
              <div>
                <Typography.Title level={5} className="mb--none">
                  {name}
                </Typography.Title>

                {accumulatedStats.subInitiatives.total > 0 && (
                  <div className="txt--secondary">
                    + Sub-initiatives ({accumulatedStats.subInitiatives.total})
                  </div>
                )}
              </div>
            </div>
            <div className="flx flx--ai-center">
              <ManageSearchIcon
                style={{ fontSize: 20, color: Colors.Action.BLUE }}
              />
            </div>
          </div>
        </div>
        <div className="flx flx--jc-space-between">
          <div className="flx-align-self-end">
            <div className="bold">
              <ProgressStats stats={stats} />
            </div>
            <h6 className="txt--secondary">
              {t('InitiativeCard.statsExplanation')}
            </h6>
          </div>

          <div style={{ width: '100px' }}>
            <InitiativePreviewGraph periodData={stats.graph} height={40} />
          </div>
        </div>
      </Link>
    </Popover>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_CARD__INITIATIVE_REPORT_LIGHT = gql`
  fragment InitiativeCard__TopInitiativeReport on TopInitiativeReport {
    initiative {
      domainId {
        itemId
        tenantId
      }
      name
      description
      startAt
      endAt
      id
      tag {
        title
        iconId
        colorCode
      }
      metadata {
        completedAt
        archived
        status
      }
    }
    accumulatedStats {
      milestones {
        completed
        overdue
        upcoming
        graph {
          date
          completed
          total
        }
      }
      activities {
        completed
        overdue
        upcoming
        graph {
          date
          completed
          total
        }
      }
      subInitiatives {
        total
      }
    }
  }
`;

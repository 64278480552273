import { Col, Row } from 'antd';
import { InitiativeCard } from '../../../../../components/initiatives/initiativeCard/InitiativeCard';
import { InitiativeSectionGridSkeleton } from './InitiativeSectionGrid.skeleton';
import { gql } from '@apollo/client';
import { InitiativeSectionRow__TopInitiativeReportFragment } from '../../../../../generated/graphql';
import { MilestoneOrActiveToggleEnum } from '../../../initiatives/initiativeDetails/milestoneOrActivityToggle/MilestoneOrActiveToggle';

interface Props {
  initiatives: InitiativeSectionRow__TopInitiativeReportFragment[];
  milestonesOrActivities: MilestoneOrActiveToggleEnum;
}

export const InitiativeSectionGrid = ({
  initiatives,
  milestonesOrActivities,
}: Props) => {
  return (
    <div>
      <div className="mt--l">
        <Row gutter={[18, 18]} className="mb--xl">
          {initiatives.map((initiativeReport) => (
            <Col xs={12} xl={8} key={initiativeReport.initiative.id}>
              <InitiativeCard
                domainId={initiativeReport.domainId}
                name={initiativeReport.initiative.name}
                tag={initiativeReport.initiative.tag}
                accumulatedStats={initiativeReport.accumulatedStats}
                milestonesOrActivities={milestonesOrActivities}
              />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

InitiativeSectionGrid.Skeleton = InitiativeSectionGridSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_SECTION_ROW__TOP_INITIATIVE_REPORT = gql`
  fragment InitiativeSectionRow__TopInitiativeReport on TopInitiativeReport {
    id
    domainId {
      itemId
      tenantId
    }
    ...InitiativeCard__TopInitiativeReport
  }
`;

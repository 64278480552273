import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { atLeastOne } from '../../../../../services/error-parser/helpers';
import './LegendSubInitiative.less';
import { MilestoneStats } from './MilestoneStats';
import { LegendSubInitiativeSkeleton } from './LegendSubInitiative.skeleton';
import { gql } from '@apollo/client';
import { InitiativeDetailsTreeNode } from '../../../../../appPages/company/initiatives/initiativeDetails/InitiativeDetails';
import { Link } from 'react-router-dom';
import { ExternalLinkIcon } from '../../../../Navigation/assets/ExternalLinkIcon';

interface Props {
  initiativeNode: InitiativeDetailsTreeNode;
  expanded: string[];
  onToggleExpanded: (id: string) => void;
}

export const LegendSubInitiative = ({
  initiativeNode,
  expanded,
  onToggleExpanded,
}: Props) => {
  const hasChildren = atLeastOne(initiativeNode.children);
  const isExpanded = expanded.includes(initiativeNode.id);

  return (
    <div
      className="LegendSubinitiative gantishShared-AppendAnimation"
      key={initiativeNode.id}
    >
      <div className="LegendSubinitiative__row">
        {hasChildren && (
          <div
            className="LegendSubinitiative__toggle"
            onClick={() => onToggleExpanded(initiativeNode.id)}
          >
            {isExpanded ? <MinusSquareOutlined /> : <PlusSquareOutlined />}
          </div>
        )}
        <div
          className="ellipsis"
          title={initiativeNode.data.gantishInitiative.name}
        >
          {initiativeNode.data.gantishInitiative.name}
        </div>
        <Link
          to={`/company/initiatives/${initiativeNode.baseInitiative.domainId.itemId}`}
        >
          <ExternalLinkIcon className="font-size--sm ml--s" />
        </Link>
        <MilestoneStats
          initiativeNode={initiativeNode}
          isExpanded={isExpanded}
        />
      </div>
      {isExpanded &&
        initiativeNode.children?.map((subInitiative) => (
          <LegendSubInitiative
            key={subInitiative.id}
            initiativeNode={subInitiative}
            expanded={expanded}
            onToggleExpanded={onToggleExpanded}
          />
        ))}
    </div>
  );
};

LegendSubInitiative.Skeleton = LegendSubInitiativeSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LEGEND_SUB_INITIATIVE__INITIATIVE_BASE = gql`
  fragment legendSubInitiative__InitiativeLight on InitiativeLight {
    id
    tag {
      title
      iconId
      colorCode
    }
  }
`;

import { useTranslation } from 'react-i18next';
import { UpdateFinancialPotentialForm_InitiativeFragment } from '../../../../../../generated/graphql';
import { gql } from '@apollo/client';
import { Form, InputNumber, FormInstance, Input } from 'antd';
import { z } from 'zod';

type Props = {
  initiative: UpdateFinancialPotentialForm_InitiativeFragment;
  form: FormInstance;
};

export const UpdateFinancialPotentialForm = ({ initiative, form }: Props) => {
  const { t } = useTranslation();

  return (
    <Form layout="vertical" form={form}>
      <Form.Item name="rev" hidden initialValue={initiative.rev}>
        <Input type="hidden" />
      </Form.Item>
      <h4>{t('UpdateFinancialPotentialForm.profit')}</h4>
      <div className="flx flx--gap">
        <Form.Item
          name={['profit', 'current']}
          initialValue={initiative.profit?.current}
          label={t('UpdateFinancialPotentialForm.current')}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name={['profit', 'potential']}
          initialValue={initiative.profit?.potential}
          label={t('UpdateFinancialPotentialForm.potential')}
        >
          <InputNumber />
        </Form.Item>
      </div>

      <h4>{t('UpdateFinancialPotentialForm.revenue')}</h4>
      <div className="flx flx--gap">
        <Form.Item
          name={['revenue', 'current']}
          initialValue={initiative.revenue?.current}
          label={t('UpdateFinancialPotentialForm.current')}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name={['revenue', 'potential']}
          initialValue={initiative.revenue?.potential}
          label={t('UpdateFinancialPotentialForm.potential')}
        >
          <InputNumber />
        </Form.Item>
      </div>
    </Form>
  );
};

export const financialPotentialSchema = z.object({
  rev: z.string(),
  profit: z.object({
    current: z.number().nullish(),
    potential: z.number().nullish(),
  }),
  revenue: z.object({
    current: z.number().nullish(),
    potential: z.number().nullish(),
  }),
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UPDATE_FINANCIAL_POTENTIAL_MODALCONTENT_INITIATIVE = gql`
  fragment UpdateFinancialPotentialForm_Initiative on Initiative2 {
    id
    rev
    profit {
      current
      potential
    }
    revenue {
      current
      potential
    }
  }
`;
